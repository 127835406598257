$fontdir: "./static/fonts/";

@font-face {
  font-family: "tt-norms-regular";
  src: local("tt-norms-regular"),
    url($fontdir + "tt-norms-regular.otf") format("opentype");
  src: url($fontdir + "tt-norms-regular.eot");
  src: url($fontdir + "tt-norms-regular.eot?#iefix") format("embedded-opentype"),
    url($fontdir + "tt-norms-regular.woff") format("woff"),
    url($fontdir + "tt-norms-regular.svg#fontname") format("svg");
}

@font-face {
  font-family: "tt-norms";
  src: local("tt-norms-bold"),
    url($fontdir + "tt-norms-bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "tt-norms-black";
  src: local("tt-norms-bold"),
    url($fontdir + "tt-norms-bold.otf") format("opentype");
  font-weight: normal;
}

$font: "calluna";
$bold: "tt-norms-bold";
$normal: "tt-norms-regular";
$medium: "tt-norms-medium";

$headersFont: "tt-norms";
$textFont: "tt-norms";

$primary-color: #e87b22;
$header-bg-color: black;
$blue-color: #0d77ce;
$light-blue-color: #0d77ce;
$green-color: #09b437;
$light-green-color: #09b437;
$orange-color: #f5af17;
$light-orange-color: #f5af17;
$red-color: #cf3d3d;
$light-red-color: #cf3d3d;
$grey-color: #666;
$placeholder-color: #747474;
$wrapper-bg-color: #eceff1;

$navbarLogoWidthSm: 50px;
$navbarLogoWidthMd: 65px;
$navbarLogoWidthLg: 80px;

$miniNavbarLogoWidthSm: 50px;
$miniNavbarLogoWidthMd: 65px;
$miniNavbarLogoWidthLg: 80px;

$navbarDrawerBreakpoint: 900px;

// wrapper min height
// frontpage
$wrapperFWMinheightXs: 100px;
$wrapperFWMinheightSm: 100px;
$wrapperFWMinheightMd: 145px;
$wrapperFWMinheightLg: 145px;
$wrapperFWMinheightXl: 204px;
// static page
$wrapperMinheightXs: 80px;
$wrapperMinheightSm: 80px;
$wrapperMinheightMd: 123px;
$wrapperMinheightLg: 123px;
$wrapperMinheightXl: 182px;

$slideBg: black;
